<template>
  <RegisterContainer />
</template>

<script>
import RegisterContainer from "@/containers/RegisterContainer";

export default {
  name: "Register",
  components: {
    RegisterContainer,
  },
};
</script>
